<template>
	<div class="main-page">
		<div class="main-menu" :style="{ width: isCollapse ? '64px!important' : '250px' }">
			<div class="mainMenuHeader">
				<img class="logo" src="../assets/img/logo.png" alt="">
				<span class="mainMenuHeader-tips" v-if="isCollapse == false">团火ERP</span>
			</div>
			<el-scrollbar style="height:90%">
				<el-menu unique-opened background-color="#fff" text-color="#3C3C3C" active-text-color="#ff720e"
					:default-active="activeIndex" router :collapse="isCollapse">
					<navMenu-left :navMenus="menuData"></navMenu-left>
				</el-menu>
			</el-scrollbar>
		</div>
		<div class="main-right" :style="{ 'margin-left': isCollapse ? '64px!important' : '250px' }">
			<navMenu-top @menuChildren="getMenuChildren" @event-collapse="isCollapse = $event"></navMenu-top>
			<div class="main-content">
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
	import NavMenuLeft from "../components/nav/left.vue";
	import NavMenuTop from "../components/nav/top.vue";
	export default {
		components: {
			'navMenu-left': NavMenuLeft,
			'navMenu-top': NavMenuTop,
		},
		data() {
			return {
				activeIndex: "dataAnalysis",
				isCollapse: false,
				menuData: [{
						id: 8,
						name: "数据罗盘",
						icon: "el-icon-data-line",
						router: 'dataAnalysis'
					},
					{
						id: 1,
						name: "仓库管理",
						icon: "el-icon-box",
						children: [
							// {
							//   id: 10,
							//   name: "仓库分类",
							//   router: 'warehouseClassify'
							// },
							{
								id: 11,
								name: "仓库资料",
								router: 'warehouseData'
							}
						]
					},
					{
						id: 2,
						name: "商品管理",
						icon: "el-icon-goods",
						children: [{
								id: 20,
								name: "商品分类",
								router: 'commodityClassify'
							},
							{
								id: 21,
								name: "商品资料",
								router: 'commodityData'
							},
							{
								id: 22,
								name: "潮汐库存",
								router: 'stock'
							}
						]
					},
					{
						id: 3,
						name: "供应商",
						icon: "el-icon-office-building",
						children: [{
							id: 30,
							name: "供应商管理",
							router: 'supplierManage'
						}]
					},
					{
						id: 4,
						name: "路线管理",
						icon: "el-icon-thumb",
						children: [{
								id: 40,
								name: "配送路线",
								router: 'deliveryRoute'
							},
							// {
							//   id: 41,
							//   name: "司机管理",
							//   router: 'driverManagement'
							// }
						]
					},
					{
						id: 5,
						name: "客户管理",
						icon: "el-icon-connection",
						children: [{
								id: 50,
								name: "客户分组",
								router: 'customerGroup'
							},
							{
								id: 51,
								name: "客户资料",
								router: 'customerData'
							},
							{
								id: 52,
								name: "黑名单",
								router: 'blacklist'
							},
							{
								id: 53,
								name: "合作申请",
								router: 'cooperationApply'
							}
						]
					},
					{
						id: 6,
						name: "销售管理",
						icon: "el-icon-document-checked",
						children: [{
								id: 60,
								name: "销售开单",
								router: 'salesBilling'
							},
							{
								id: 61,
								name: "订单列表",
								router: 'orderList',
								children: [{
										id: 611,
										name: "普通订单",
										router: 'orderList',

									},
									{
										id: 612,
										name: "信用订单",
										router: 'orderListXyf'
									},
									{
										id: 613,
										name: "按地址合并",
										router: 'orderListAddress'
									},
								]
							},
							{
								id: 64,
								name: "售后管理",
								router: 'afterSales'
							},
							{
								id: 65,
								name: "销售统计",
								router: 'salesSummary',
								children: [{
										id: 651,
										name: "商品汇总",
										router: 'salesSummary'
									},
									{
										id: 652,
										name: "未配汇总",
										router: 'salesSummaryNoDelivery'
									},
									{
										id: 653,
										name: "收款方式",
										router: 'salesSummaryPayType'
									},
									{
										id: 654,
										name: "团长统计",
										router: 'salesSummaryLeader'
									},
								]
							},

							{
								id: 67,
								name: "截单时间",
								router: 'cutTimeSet'
							}
						]
					},
					{
						id: 7,
						name: "配送管理",
						icon: "el-icon-position",
						children: [{
								id: 70,
								name: "配送单",
								router: 'preDeliveryNote',
								children: [{
										id: 701,
										name: "普通订单",
										router: 'preDeliveryNote'
									},
									{
										id: 702,
										name: "按地址合并",
										router: 'preDeliveryNoteAddress'
									},
									{
										id: 703,
										name: "按商品展示",
										router: 'preDeliveryNoteGoods'
									},
								]
							},
							{
								id: 73,
								name: "配送汇总",
								router: 'preDeliverySummary',
								children: [{
										id: 731,
										name: "商品汇总",
										router: 'preDeliverySummary'
									},
									{
										id: 732,
										name: "商品汇总（采购）",
										router: 'preDeliverySummaryDay'
									},
								]
							},

							{
								id: 75,
								name: "配送费预查",
								router: 'preDeliveryCheck'
							},
							{
								id: 76,
								name: "休息日设置",
								router: 'restDaySet'
							}
						]
					},
					{
						id: 9,
						name: "采购入库",
						icon: "el-icon-takeaway-box",
						children: [{
								id: 91,
								name: "入库",
								router: 'warehouseReceipt',
								children: [{
										id: 911,
										name: "入库开单",
										router: 'warehouseReceipt'
									}, {
										id: 912,
										name: "入库管理",
										router: 'warehouseManagement'
									},
									{
										id: 913,
										name: "入库汇总",
										router: 'warehouseSummary'
									},
								]
							},
							{
								id: 93,
								name: "退库",
								router: 'tkdAdd',
								children: [{
										id: 931,
										name: "退库开单",
										router: 'tkdAdd'
									}, {
										id: 932,
										name: "退库管理",
										router: 'tkdLst'
									},
									{
										id: 933,
										name: "退库汇总",
										router: 'tkdSummary'
									}
								]
							},

						]
					},
					{
						id: 1010,
						name: "财务管理",
						icon: "el-icon-copy-document",
						children: [{
								id: 10101,
								name: "收入",
								router: 'incomeTypeLst',
								children: [{
										id: 1013,
										name: "其他收入科目",
										router: 'incomeTypeLst'
									},
									{
										id: 1014,
										name: "其他收入管理",
										router: 'incomeLst'
									},
									{
										id: 1011,
										name: "总收入统计",
										router: 'dayIncomeSummary'
									},
									// {
									// 	id: 1012,
									// 	name: "月收入统计",
									// 	router: 'monthIncomeSummary'
									// },
								]
							},
							{
								id: 10102,
								name: "费用",
								router: 'incomeLst',
								children: [{
										id: 1015,
										name: "费用科目",
										router: 'feeTypeLst'
									},
									{
										id: 1016,
										name: "费用管理",
										router: 'feeLst'
									},
									{
										id: 1017,
										name: "费用统计",
										router: 'dayFeeSummary'
									},
									// {
									// 	id: 1018,
									// 	name: "月费用统计",
									// 	router: 'monthFeeSummary'
									// },
								]
							},
							{
								id: 10103,
								name: "统计报表",
								router: 'financeReport'
							}
						]
					},
					{
						id: 1020,
						name: "微信助手",
						icon: "el-icon-chat-line-round",
						children: [{
								id: 1025,
								name: "配置",
								router: 'wxsetting'
							}, {
								id: 1021,
								name: "群组管理",
								router: 'groupManage'
							},
							{
								id: 1022,
								name: "定时推送",
								router: 'scheduledPush'
							},
							{
								id: 1023,
								name: "即时推送",
								router: 'push'
							},
							{
								id: 1024,
								name: "接收消息",
								router: 'messageLst'
							}
						]
					}
				]
			};
		},
		mounted() {
			this.activeIndex = sessionStorage.getItem('activeMenu')
		},
		methods: {
			getMenuChildren(data) {
				this.menuData = data
			},
		}
	};
</script>

<style lang="scss" scoped>
	.main-page {
		width: 100%;
		height: 100%;

		.main-menu {
			transition: width 0.28s;
			background-color: #fff;
			height: 100%;
			position: fixed;
			font-size: 0px;
			top: 0;
			bottom: 0;
			left: 0;
			z-index: 1001;
			overflow: hidden;

			.mainMenuHeader {
				position: relative;
				height: 60px;
				background: #fff;
				display: flex;
				justify-content: center;
				align-items: center;
				border-right: 1px solid #F5F5F5;
				border-bottom: 1px solid #F5F5F5;

				.logo {
					width: 30px;
					height: 30px;
					vertical-align: middle;
					margin-right: 6px;
				}

				.mainMenuHeader-tips {
					font-size: 24px;
					font-weight: bold;
					color: #2C2C2C;
				}
			}

			.el-menu {
				border-right: none;
			}

			/*隐藏文字*/
			/deep/.el-menu--collapse .el-submenu__title span {
				display: none;
			}

			/*隐藏 > */
			/deep/.el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
				display: none;
			}
		}

		.main-right {
			min-height: 100%;
			transition: margin-left 0.28s;
			position: relative;
			background: #F0F1F4;

			.main-content {
				padding: 15px;
			}
		}
	}

	/deep/.el-scrollbar__wrap {
		overflow-x: hidden;
	}
</style>